import React from "react"

import Layout from "../components/layout"

import "../styles/cv.css"

export default () => (

<Layout>
<div id="content">
<meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
<meta name="Generator" content="Microsoft Word 14 (filtered)" />
<style dangerouslySetInnerHTML={{__html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tmargin-bottom:.0001pt;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",\"sans-serif\";}\na:link, span.MsoHyperlink\n\t{color:blue;\n\ttext-decoration:underline;}\na:visited, span.MsoHyperlinkFollowed\n\t{color:purple;\n\ttext-decoration:underline;}\np.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.5in;\n\tmargin-bottom:.0001pt;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",\"sans-serif\";}\np.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.5in;\n\tmargin-bottom:.0001pt;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",\"sans-serif\";}\np.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.5in;\n\tmargin-bottom:.0001pt;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",\"sans-serif\";}\np.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.5in;\n\tmargin-bottom:.0001pt;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",\"sans-serif\";}\nspan.MsoIntenseReference\n\t{font-variant:small-caps;\n\tcolor:#C0504D;\n\tletter-spacing:.25pt;\n\tfont-weight:bold;\n\ttext-decoration:underline;}\n.MsoChpDefault\n\t{font-family:\"Calibri\",\"sans-serif\";}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in .5in .5in .5in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n" }} />
<div className="WordSection1">
<div style={{border: 'none', borderBottom: 'solid windowtext 1.0pt', padding: '0in 0in 1.0pt 0in'}}>
<p className="MsoNormal" align="center" style={{textAlign: 'center', border: 'none', padding: '0in'}}><span lang="DE" style={{fontSize: '22.0pt', fontFamily: '"Arial","sans-serif"'}}>Lebenslauf&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Irma Servatius</span></p>
</div>
<p className="MsoNormal"><img width={155} height={205} src="http://servati.us/images/_IGP6018a.JPG" align="right" hspace={12} alt="Bild von Shannon Laine" /></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase'}}>Persönliche Daten</span></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Name:
  Irma Servatius</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Telefon:
  <span style={{color: '#222222', background: 'white'}}>+43 677 618 277 07</span></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>E-Mail:
  <a href="mailto:bookings@servati.us">bookings@servati.us</a></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Webseite:
  http://servati.us</span></p>
</div>
<p className="MsoNormal"><span lang="DE" style={{fontSize: '7.0pt', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase'}}>Studium</span></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>University
of Massachusetts, Lowell, MA (2004-2006)</span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>M.M. Musik. Konzertfach Viola mit Vollstipendium, und
Pädagogische &nbsp;Assistentin</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>WPI,
Worcester, MA (2000-2004) </span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Bachelorabschluss in Musik und Electrotechnik</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Privatunterricht
mit </span></p>
<p className="MsoNormal" style={{marginLeft: '.25in'}}><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Michael Zaretzky (2010, Boston Symphony Orchestra); Herbert
Kefer (2007, Artis-Quartett); </span></p>
<p className="MsoNormal" style={{marginLeft: '.25in'}}><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Peter Sulski (2003 – 2008, London Symphony Orchestra);
Mark Berger (2007-2008, Lydian String Quartet); Meisterklasse mit Sam Bergman
and Kate Holzemer (2008)</span></p>
<p className="MsoNormal" style={{marginLeft: '.25in'}}><span lang="DE" style={{fontSize: '7.0pt', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase'}}>Künstlerische Initiativen</span></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Privatunterricht,
auch in öffentlichen Schulen (seit 2003)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Künstlerische
Leiterin, All Saints Mittagskonzerte (wöchentlich, ganzjährig, 2007-2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Assistentin
des Künstlerischen Leiters: Thayer Chamber Music Festival (2005-2008)</span></p>
<p className="MsoNormal"><span style={{fontFamily: '"Arial","sans-serif"'}}>Betriebsleiterin,
Worcester Collegium Youth and Worcester Collegium Strings (2006-2009)</span></p>
<p className="MsoNormal"><span style={{fontSize: '7.0pt', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase'}}>Mitgliedschaft (als Bratschistin)</span></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Bangor
Symphony Orchestra, unter der Leiterung von Lukas Richman: Festanstellung. Sitz
2&amp;5 (seit 2013)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Carlisle
Chamber Orchestra, unter der Leiterung von Alan Yost. Gründungsmitgliederin (seit
2013)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Respiro
Quartet und Trio (gegründet aus Mitglieder Kammerwerke) (seit 2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Concord
Symphony Orchestra, unter der Leiterung von Richard Pittman (seit 2007)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Thayer
Symphony Orchestra, unter der Leiterung von Toshimasa Francis Wada (seit 2006)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Worcester
Collegium Strings, unter der Leiterung von Ian Watson (2006-2008) </span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Metrowest
Symphony Orchestra, unter der Leiterung von Peter Cokkinias: Solobratschistin
(2006-2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Civic
Symphony Orchestra of Boston, unter der Leiterung von Max Hobart: mit
Stipendium (2006-2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Kammerwerke
Chamber Orchestra, unter der Leiterung von Alan Pearlmutter (2007-2011)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>New
England Philharmonic, unter der Leiterung von Richard Pittman (2007-2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>New
Philharmonia Orchestra, unter der Leiterung von Ron Knusden (2006)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Medwin
Honors String Quartet: mit Stipendium (2001-2004)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontSize: '7.0pt', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase'}}>Besondere Auftritte</span></span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Amerikanische
Uraufführrung von 2.Viola Sonate von Otto Siegl für die Austrian American
Society (2014)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Respiro
Triokonzert: Beethoven und Dohnanyi bei Wilmington Memorial Library (2014)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Solistin
in Brandenburg 6, für den Concord Chamber Orchestra (2011 und 2013)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Solistin
in Mozart’s Sinfonia Concertante mit dem WPI Orchestra (2012)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Respiro
Quartettkonzert. Schumann Geburtstags Feier, Wilmington Memorial Libary (2010)</span></p>
<p className="MsoNormal"><span style={{fontFamily: '"Arial","sans-serif"'}}>Respiro Quartettkonzert.
Bolton Philharmonic Society (2010)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Solo
Bratschenkonzert: Goddard Chapel, Tufts University als Teil ihrer Konzertreihe
(2010)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Solo
Bratschenkonzert: Privataufführung in Worcester, MA: Bach, Brahms, Hindemith &amp;
Penderecki (2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Chamber
Music America: Zuschuss zu dem National Performing Arts Convention (2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Apple
Hill Center for Chamber Music: Teilnehmer mit Stipiendium (2008)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Thayer
Chamber Music Festival, Teilnehmer mit Forschungsstipendium (2005-2009)</span></p>
<p className="MsoNormal"><span style={{fontFamily: '"Arial","sans-serif"'}}>University of
Connecticut, Chamber Music Festival, Teilnehmer (2007)</span></p>
<p className="MsoNormal"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Classicopia,
Teilnehmer mit Stipendium (2005)</span></p>
<p className="MsoNormal" style={{textIndent: '.25in'}}><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
<p className="MsoNormal"><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase'}}>Sprachen &amp; Hobbies</span></span><span className="MsoIntenseReference"><span lang="DE" style={{fontFamily: '"Arial","sans-serif"', fontVariant: 'normal !important', color: 'windowtext', textTransform: 'uppercase', fontWeight: 'normal', textDecoration: 'none'}}> </span></span><span lang="DE" style={{fontFamily: '"Arial","sans-serif"'}}>Englisch, Deutsch, Kunst, Fotografie,
Wandern, Dichten, Musik Druckauflage</span></p>
</div>

      </Layout>

)
